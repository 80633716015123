import React, { useEffect, useState } from 'react'
import Carniversario from '../../../static/images/carniversario.png'
import ExternalButton from '../Button/ExternalButton'
import { AiOutlineClose } from 'react-icons/ai'
import moment from 'moment/moment'

const SESSION_STORAGE_COUNTER_KEY = 'closeCounter'
const MAX_COUNTER_TO_SHOW = 2

const EventModal = () => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const endOfEventDate = moment('12112023', 'DDMMYYYY')
    const todayDate = moment()

    const showModalByDate = todayDate.isBefore(endOfEventDate)

    const counterValue = sessionStorage.getItem(SESSION_STORAGE_COUNTER_KEY)
    const showModalByCloseCounter = counterValue < MAX_COUNTER_TO_SHOW

    setOpen(showModalByDate && showModalByCloseCounter)
  }, [])

  function storeCloseButtonClickCounter() {
    const counterValue = sessionStorage.getItem(SESSION_STORAGE_COUNTER_KEY)
    let counterValueToSet
    if (counterValue) {
      counterValueToSet = parseInt(counterValue) + 1
    } else {
      counterValueToSet = 1
    }

    sessionStorage.setItem(
      SESSION_STORAGE_COUNTER_KEY,
      counterValueToSet.toString()
    )
  }

  return (
    open && (
      <div className="w-screen h-screen fixed top-0 left-0 bg-opacity-75 bg-gray-700 flex items-center justify-center overscroll-none z-50">
        <div className="bg-gray-900 opacity-100 flex rounded-md p-4 items-end flex-col gap-4 max-h-full mx-2">
          <AiOutlineClose
            className="h-8 w-8 cursor-pointer text-white hover:text-gray-400"
            onClick={() => {
              storeCloseButtonClickCounter()
              setOpen(false)
            }}
          />
          <div className="flex flex-1">
            <img
              src={Carniversario}
              style={{ maxHeight: '80vh' }}
              alt="Evento Carniversario"
            />
          </div>
          <ExternalButton
            link="https://shotgun.live/events/carniversario"
            title="Compre seu ingresso!"
          />
        </div>
      </div>
    )
  )
}
export default EventModal
