import React from 'react'
import Div from '../Motion/Div/Div'
import InternalButton from '../Button/InternalButton'
import ExternalButton from '../Button/ExternalButton'

const KnowMenuSection = ({ title, description, button, image }) => {
  return (
    <section
      className="banner"
      style={{
        background: `linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 35%, rgba(0,0,0,0) 100%) ,url(${image})`
      }}
    >
      <div className="flex flex-col max-w-screen-xl mx-auto lg:flex-row py-32 px-5 items-start">
        <Div className="p-4 md:p-8 w-full md:w-1/2">
          <h2 className="text-2xl text-white mb-5">{title}</h2>
          {description && (
            <p className="mreaves text-base text-white mb-5">{description}</p>
          )}
          <div className="flex">
            <ExternalButton
              link="https://deliveryapp.neemo.com.br/delivery/16053/menu"
              title={button.title}
            />
          </div>
        </Div>
      </div>
    </section>
  )
}
export default KnowMenuSection
